import React, { useState, useEffect, useRef } from 'react';
import './classes/Header.css';
import './classes/CountdownHeader.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CartTooltip from './CartTooltip';
import MinicartLength from './MinicartLength';
import { configData } from "../config";

function Header() {

  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [menuLinks, setMenuLinks] = useState([]);
  const [CountdownHeader, setCountdownHeader] = useState([]);
  const [ShowCountdownHeader, setShowCountdownHeader] = useState(false);
  const [scripts, setScripts] = useState();
  const [mobilemenuLinks, setMobileMenuLinks] = useState([]);
  const [height, setHeight] = useState();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [masquerade, setMasquerade] = useState(false);
  const [targetDate, setTargetDate] = useState(new Date('2024-09-10T10:00:00'));
  const [activeClass, setActiveClass] = useState({
    menuBtn: '',
  })
  
  const total = cartItems.reduce((acc, item) => acc + item.calculated_amount, 0);
  const tooltipRef = useRef(null);
  const app_url = configData.APP_URL;
  const navigate = useNavigate();
  const uid = localStorage.getItem('uid');
  const drupal_uid = localStorage.getItem('drupal_uid');
  const domain_url = configData.DOMAIN_URL;
  const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
  const handleCartClick = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setTooltipVisible(false);
    }
  };

  useEffect(() => {
    retrieveMenuLinks();
    retrieveMobileMenuLinks();
    if(localStorage.getItem('uid')) {
      checkimpersonateuser();
      if(localStorage.getItem('uid') === 'undefined') {
        localStorage.removeItem('uid');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('order_id');
      }
    }
    returnCountDownHeader();
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    retrieveCartProducts();
    if (isTooltipVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTooltipVisible]);
  
  
  const totalTime = targetDate - currentTime;
  const seconds = Math.floor((totalTime / 1000) % 60);
  const minutes = Math.floor((totalTime / (1000 * 60)) % 60);
  const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
  const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));
  const sDays = days < 10 ? (0+days.toString()) :(days.toString());
  const shours = hours < 10 ? (0+hours.toString()) :(hours.toString());
  const sminutes = minutes < 10 ? (0+minutes.toString()) :(minutes.toString());
  const sseconds = seconds < 10 ? (0+seconds.toString()) :(seconds.toString());
  const handleMobileMenu = (className) => {
    
    setActiveClass({
      [className]: 'act',
      mainMenu: 'act'
    });
    if(activeClass.menuBtn == "act") {
      setActiveClass({
        [className]: '',
        mainMenu: ''
      });
    }
    

  }
  const toggle_class = (className) => {
   
    const height1 = document.getElementById('subMenu').clientHeight;
    const height2 = document.getElementById('subMenu1').clientHeight;
    const height3 = document.getElementById('mainMenu').clientHeight;
    const maxheight = Math.max(height1,height2,height3);
    setHeight({height:maxheight});
    const class_name = className;
    setActiveClass(prevState => ({
      ...prevState,
      [class_name]: 'act'
    }));
   
    
  };
  const back_button = (className) => {
    setActiveClass(prevState => ({
      ...prevState,
      [className]: ''
    }));
    
  };
  const remove_class = () => {
    
    setActiveClass({
      mainMenu: '',
      menuBtn: ''
    });
    

  }
  
  const hideCountdown = (event) => {
    setShowCountdownHeader(false);
    localStorage.setItem('hide_countdown', true);
  };
  
  async function retrieveCartProducts() {
    const order_id = localStorage.getItem('order_id');
    if( order_id) {
    const response = await fetch(
      app_url+'smwwcommercepay/getuserorders?order_id='+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    
    setCartItems(responsedata.data);
  }
  }
  
  
  async function checkimpersonateuser() {
    const sid = localStorage.getItem('session_id');
    const response = await fetch(
    app_url+'site/checkmasquerade?sid='+sid,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const data = await response.json();
  const resultdata = data.data;
  if(resultdata.masquerade === "ON") {
    setMasquerade(true);
    localStorage.setItem('uid', resultdata.result.uid);
    localStorage.setItem('username', resultdata.result.username);
    localStorage.setItem('email', resultdata.result.email);
    localStorage.setItem('drupal_uid', resultdata.result.drupal_uid);
    localStorage.setItem('masquerade', 1);
  }
  if(resultdata.masquerade === "OFF") {
    setMasquerade(false);
    localStorage.setItem('uid', resultdata.result.uid);
    localStorage.setItem('username', resultdata.result.username);
    localStorage.setItem('email', resultdata.result.email);
    localStorage.setItem('drupal_uid', resultdata.result.drupal_uid);
    localStorage.setItem('masquerade', 0);
    localStorage.setItem('session_id', resultdata.session_id);
  }
  }
  async function returnCountDownHeader() {
    const countdownresponse = await fetch(
    app_url+'smwwheader/getcountdownheader',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const countdowndata = await countdownresponse.json();
  const countdownresult = countdowndata.data.result;
  const target_date = new Date(countdowndata.data.result.end_date);
  const today_date = new Date();
  setCountdownHeader(countdownresult);
  
  if(countdownresult.show_on_front_end === "1") {
    setShowCountdownHeader(true);
  } 
  if(localStorage.getItem('hide_countdown') === "true") {
    setShowCountdownHeader(false);
  }
  
  if(target_date <= today_date) {
    setShowCountdownHeader(false);
  }
  setTargetDate(new Date(countdowndata.data.result.end_date));

  }
  async function retrieveMenuLinks() {
    const menulistresponse = await fetch(
    app_url+'smwwheader/getsmwwmenu',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const menulistdata = await menulistresponse.json();
  const menulist = menulistdata.data.all_menus;
  setMenuLinks(menulist);
  
  }
  async function retrieveMobileMenuLinks() {
    const menulistresponse = await fetch(
    app_url+'smwwheader/getsmwwmobilemenu',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const menulistdata = await menulistresponse.json();
  const menulist = menulistdata.data.all_menus;
  setMobileMenuLinks(menulist);
  
  }
  const handleLogout = async event => {
    event.preventDefault();
    const response = await fetch(
      app_url+'site/logout?action='+"logout",
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      const data = await response.json();
      const resultdata = data.data;
      if(resultdata.msg === "Success") {
        
        localStorage.removeItem('uid');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('order_id');
        if(resultdata.application_user === true) {
          navigate("/login");
        } else {
          const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
          window.location.href = drupal_domain_url+'/api/user/logout';
          
        }
        
      } 
  };
  const handleStopImpersonate = async event => {
    event.preventDefault();
    const sid = localStorage.getItem('session_id');
    const response = await fetch(
      app_url+'site/stopimpersonate?sid='+sid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json();
  const resultdata = data.data;
  if(resultdata.redirect_url) {
    window.location.href = resultdata.redirect_url;
  }
  };
  return (
    <>

<Helmet>
{scripts}

</Helmet>
  <title>Sports Management Worldwide</title>
  
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
  />
  
  {ShowCountdownHeader &&
  <div className="countdown_container" id="countdown_container">
    <div className="countdown_wrapper des_countdown_wrapper">
    <div className="headline_text">{CountdownHeader.headline_text}</div>
    <div className="countdown_content_wrapper">
      <div className="countdown_date_wrapper">
		    <div className="countdown_days" >
     
          {Object.keys(sDays).map(index => {
            return <span id="countdown_days_number_1" className="countdown_days_number countdown_number">{sDays[index]}</span>
          })}
			
			    <div className="countdown_days_text countdown_text">Days</div>
		    </div>

		<div className="countdown_hours">
      {Object.keys(shours).map(index => {
            return <span id="countdown_hours_number_1" className="countdown_hours_number countdown_number">{shours[index]}</span>
      })}
			
			<div className="countdown_hours_text countdown_text">Hours</div>
		</div>

		<div className="countdown_minutes">
      {Object.keys(sminutes).map(index => {
            return <span id="countdown_hours_number_1" className="countdown_hours_number countdown_number">{sminutes[index]}</span>
      })}
			
			<div className="countdown_minutes_text countdown_text">Minutes</div>
		</div>

		<div className="countdown_seconds">
      {Object.keys(sseconds).map(index => {
            return <span id="countdown_seconds_number_1" className="countdown_seconds_number countdown_number">{sseconds[index]}</span>
      })}
			
			<div className="countdown_seconds_text countdown_text">Seconds</div>
		</div>

	</div>

	<div className="countdown_enroll_now"><a href={CountdownHeader.button_link}>{CountdownHeader.button_text}</a></div>

	</div>

	</div>

	<span className="countdown_close_icon des_countdown_close_icon" onClick={hideCountdown}>&#x2715;</span>

	<div className="countdown_wrapper mob_countdown_wrapper">

		<div className="headline_text">{CountdownHeader.headline_text}</div>

		<span className="countdown_close_icon mob_countdown_close_icon" onClick={hideCountdown} >✕</span>

		<div className="countdown_content_wrapper">

			<div className="countdown_date_wrapper">

				<div className="countdown_days" >
					<span id="countdown_days_number" className="countdown_days_number countdown_number">{days}<span className="subtext">Days</span></span>
				</div>

				<div className="countdown_hours">
					<span id="countdown_hours_number" className="countdown_hours_number countdown_number">{hours}<span className="subtext">Hours</span></span>
				</div>

				<div className="countdown_minutes">
					<span id="countdown_minutes_number" className="countdown_minutes_number countdown_number">{minutes}<span className="subtext">Mins</span></span>
				</div>
	
				<div className="countdown_seconds">
					<span id="countdown_seconds_number" className="countdown_seconds_number countdown_number">{seconds}<span className="subtext">Secs</span></span>
				</div>

			</div>

			<div className="countdown_enroll_now"><a href={CountdownHeader.button_link}>{CountdownHeader.button_text}</a></div>

		</div>
	</div>
</div>
}
  <div id="header">
    <div className="navbg" />
    <div className="header">
      <div className="mobile">
        <div className="mainContainer">
          <header>
            <nav id="mainMenu" style={height} className={`mainMenu ${activeClass.mainMenu ? 'act' : ''}`}>
              <ul>
                <li><img onClick={() => remove_class()} className="closeact" src={domain_url+"images/new_close_icon.png"} style={{ float: "right" }}alt="Smiley face" /></li>
                <li style={{ clear: "both" }}>
						      <img  src={domain_url+"images/smw-logo.png"} alt="Smiley face" />
                    {mobilemenuLinks.map((item, index) => (
                      <>
                      {Object.keys(item).map(links => (
                        <>
                          <li onClick={() => toggle_class(links)}>
							              <img  src={item[links].image_path} alt="Smiley face" />
							              <a href="#">{item[links].link_title}</a>
						              </li>
                          
                          <div id="subMenu" style={height} className={`${links} ${activeClass[links] ? 'act' : ''}`}>
                            <ul> 
					                    <li style={{ marginBottom: 8 }}>
                                <img onClick={() => remove_class()} className="closeact" src={domain_url+"images/new_close_icon.png"} style={{ float: "right" }}alt="Smiley face" />
								                <a style={{ color: "black", float: "left" }} className ="main-back-btn"  onClick={() => back_button(links)} href="#"> &#8592;Back</a>
							                </li>
							                <li>
								                <a  className="sub-heading-menu" style={{ textAlign: "center" }} href={item[links].link_path}>
								                  <img  src={item[links].image_path} alt="Smiley face" />
								                </a>
							                </li>
                              {item[links]['sub_menu'].map((subitem, subindex) => ( 
                                <>
                                  <li onClick={() => toggle_class(subitem.mlid)}>
											              <a href={subitem.link_path}>{subitem.link_title}</a>
										              </li><hr/>
                                  {subitem['sub_menu'] &&
                                      <>
										              <div id="subMenu1" style={height} className={`${subitem.mlid} ${activeClass[subitem.mlid] ? 'act' : ''}`}>
											              <ul> 
                                      <li style={{ marginBottom: 8 }}>
												                <img onClick={() => remove_class()} class="closeact" src={domain_url+"images/new_close_icon.png"} style={{ float: "right" }}alt="Smiley face" />
								                        <a style={{ color: "black", float: "left" }} className ="main-back-btn"  onClick={() =>back_button(subitem.mlid)} href="#"> &#8592;Back</a>
											                </li>
                                      <li>
                                        <a  className="sub-heading-menu" style={{ textAlign: "center" }} href={subitem.link_path}>
								                          <img  src={subitem.image_path} alt="Smiley face" />
								                        </a>
											                </li>
                                     
                                      {subitem['sub_menu'].map((csubitem, csubindex) => ( 
                                        <><li>
                                        <a href={csubitem.link_path}>{csubitem.link_title}</a>
                                        </li><hr/>
                                        </>
                                      ))}
                                     
                                    </ul>
                                  </div>
                                  </>
                                }
                                  </>
                              ))}
                              </ul>
                          </div>
                        </>
                      ))} 
                      </>
                    ))}
					      </li>
              </ul>
            </nav>
          </header>
        </div>
      </div>
      <div className="logo">
        <a href="/" title="Home" />
      </div>
      <div className="logot">
        <a href="/">
          <img
            className="lazyload agency_logo_desktop"
            src={`${process.env.PUBLIC_URL}/images/smww_header_logo.png`}
            width="100%"
          />
        </a>
      </div>
      <div className="toprihht">
        <span className="mobile_call">
          <b>
            Sports Management Worldwide
            <br />
          </b>
          The Global Leader in Sports
          <br />
          Business Education
          <br />
        </span>
        <span className="call">
          <b>
            Sports Management Worldwide
            <br />
          </b>
          Call{" "}
          <a href="tel:503 445 7105">
            <em>US 503 445 7105</em>
          </a>
          <br />
          Call{" "}
          <a href="tel:+44 (0) 8712 884799">
            <em>UK +44 (0) 8712 884799</em>
          </a>
        </span>
        <div className="mobile_phone_num">
          <i className="fa fa-phone" aria-hidden="true">
            &nbsp;
          </i>
          <a href="tel:503 445 7105">
            <em>(503) 445-7105</em>
          </a>
          <br />
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n\t\t\t @media (min-width:501px){.share{display:flex;justify-content:space-between;}}\n\t\t\t @media (max-width:500px){.share{margin-right:0px !important;}}\n\t\t\t "
          }}
        />
        <div className="share">
          <a
            target="_blank"
            href="https://www.youtube.com/user/SportsManagementWW"
            title="Youtube"
          >
            <span className="a" />
          </a>
          <a target="_blank" href="https://twitter.com/SMWW" title="Twitter">
            <span className="b" />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/school/sports-management-worldwide/?"
            title="LinkedIn"
          >
            <span className="c" />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/pages/Sports-Management-Worldwide/58059955899"
            title="Facebook"
          >
            <span className="d" />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/sportsmanagementworldwide/?hl=en"
            title="Instagram"
          >
            <span className="e" />
          </a>
          <a
            target="_blank"
            href="https://open.spotify.com/show/3S8SASVW9Wn7379um9IXVM?si=33d288d9342a442e"
            title="Spotify"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/spotify-logo-new.png`}
              width={25}
              style={{ verticalAlign: "unset" }}
            />
          </a>
        </div>

        <Link>
        <div className="tool-box">
          <div className="top-header-cart-icon cart-icon" id="top-header-cart-icon" onClick={handleCartClick}>
            <div id="totalItems">{cartItems.length}</div>
          </div>
          {isTooltipVisible && (
            <div id="minicartdetails" className="minicartdetails" ref={tooltipRef}>
              <CartTooltip cartItems={cartItems} total={total} />
            </div>
          )}
        </div>
      </Link>

    
        <div className="minicart"></div>
        {uid > 0 &&
          <div className='header-hello-text'>Hello <b><a href={"/user/"+uid}>{localStorage.getItem('username')}</a></b> <Link onClick={handleLogout}>Logout</Link></div>
        }

      </div>
      {/*nav*/}
      <div className="nav" id="">
        <div className="mobile">
          <div className="share" style={{}}>
          <Link to="/application" className='sticky-header-button mobile_apply_button'>Apply Now Free</Link>
          </div>
          <div className="mainContainer">
            <header>
              <a href="#" className={`menuBtn ${activeClass.menuBtn ? 'act' : ''}`} onClick={() => handleMobileMenu('menuBtn')}>
                <span className="lines" />
              </a>
            </header>
          </div>
        </div>
        <div className="ruby-wrapper">
          <ul className="ruby-menu">
            
              {menuLinks.map((item, index) => (
                <>
                  {Object.keys(item).map(links => (
                    <>
                      {links == 588 ? (  
                      <>
                        <li className="ruby-menu-mega-blog">
                          <a href={item[links].link_path}>{item[links].link_title}</a>
                            <div style={{ height: "auto" }} className="">
                              <ul className="ruby-menu-mega-blog-nav">
                                <div className="ruby-grid ruby-grid-lined" style={{ height: "auto" }}>
                                  <div className="ruby-row">
                                    <div className="ruby-col-4">
                                      <div className="stm-sta-style">
                                        {item[links]['sub_menu'].slice(0, 3).map((subitem, subindex) => ( 
                                          <>
                                           <li className="stm-f1" style={{ color: "#003c7d", marginBottom: 22 }}>
                                              <div style={{ marginBottom: 4, fontWeight: "bold" }} className="stm-sta-style">
                                                <a style={{ color: "#003c7d" }} href={subitem.link_path}>{subitem.link_title}</a>
                                              </div>
                                              {subitem['sub_menu'] &&
                                                <ul>
                                                {subitem['sub_menu'].map((csubitem, csubindex) => ( 
                                                <>
                                                  <li><a className="cool-link" href={csubitem.link_path}>{csubitem.link_title}</a></li>  
                                                </>
                                                ))}
                                         
                                                </ul>
                                              }
                                            </li>
                                           
                                          </>
                                        ))}
                                        </div>
                                        </div>
                                        <div className="ruby-col-4">
                                         <div className="stm-sta-style">
                                        {item[links]['sub_menu'].slice(3, 9).map((subitem, subindex) => ( 
                                          <>
                                           
                                            <li className="stm-f1" style={{ color: "#003c7d", marginBottom: 22 }}>
                                              <div style={{ marginBottom: 4, fontWeight: "bold" }} className="stm-sta-style">
                                              <a style={{ color: "#003c7d" }} href={subitem.link_path}>{subitem.link_title}</a>
                                              </div>
                                              {subitem['sub_menu'] &&
                                                <ul>
                                                {subitem['sub_menu'].map((csubitem, csubindex) => ( 
                                                  <>
                                                  
                                                  <li><a className="cool-link" href={csubitem.link_path}>{csubitem.link_title}</a></li>  
                                                  </>
                                                ))}
                                         
                                                </ul>
                                              }
                                            </li>
                                           
                                          </>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="ruby-col-4">
                                         <div className="stm-sta-style">
                                        {item[links]['sub_menu'].slice(9, 11).map((subitem, subindex) => ( 
                                          <>
                                           
                                            <li className="stm-f1" style={{ color: "#003c7d", marginBottom: 22 }}>
                                              <div style={{ marginBottom: 4, fontWeight: "bold" }} className="stm-sta-style">
                                              <a style={{ color: "#003c7d" }} href={subitem.link_path}>{subitem.link_title}</a>
                                              </div>
                                              {subitem['sub_menu'] &&
                                                <ul>
                                                {subitem['sub_menu'].map((csubitem, csubindex) => ( 
                                                  <>
                                                  
                                                  <li><a className="cool-link" href={csubitem.link_path}>{csubitem.link_title}</a></li>  
                                                  </>
                                                ))}
                                         
                                                </ul>
                                              }
                                            </li>
                                           
                                          </>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </li>
                        </>
                      ) : (
                      <>
                        <li className="">
                          <a href={item[links].link_path}>{item[links].link_title}</a>
                            <ul className="">
                                {item[links]['sub_menu'].map((subitem, subindex) => ( 
                                  <><li>
                                  <a href={subitem.link_path}>
                                    {subitem.link_title}
                                  </a>
                                  {subitem['sub_menu'] &&
                                    
                                    <ul>
                                      {subitem['sub_menu'].map((csubitem, csubindex) => ( 
                                        <li><a href={csubitem.link_path}>
                                        {csubitem.link_title}
                                        </a></li>  
                                      ))}
                                         
                                    </ul>
                                  }
                                  </li>
                                  </>
                                ))}
                            </ul>
                        </li>
                      </>
                    )}
                    </>
                 ))}
                  <span className="ruby-dropdown-toggle" />
                  
                </>
                
              ))}
            {!uid &&
              <li className="logs">
                <Link to="/login">Login</Link>
              </li>
            }
            <li className="ruby-menu-right1">
              <span>
                {" "}
                <Link to="/application">Apply Now Free</Link>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="blank20" />
  {/* EOF: #header */}
  {/*main*/}
  <div id="main">
    {/*left*/}
    <div className="left"></div>
    {/* EOF: #left */}
    {/*center*/}
    <div className="center left-center">
      <div className="region region-content">
        <div id="block-system-main" className="block block-system">
          <div className="content">
            <div
              id="node-523"
              className="node node-page"
              about="/home_page"
              typeof="foaf:Document"
            >
              <div className="content clearfix"></div>
            </div>
          </div>
        </div>
        <div className="clearfix">
          <div className="links" />
        </div>
      </div>
    </div>
  </div>
  {masquerade &&
  <div className='user-impersoanting'>You are impersonating <a href={drupal_domain_url+'/user/'+drupal_uid}><i>{localStorage.getItem('username')}</i>.</a> &nbsp;  <a onClick={handleStopImpersonate}>Switch back</a></div>
  }
  <div id="block-block-151" className="block block-block">
    <div className="content"></div>
  </div>
  {/*left*/}
  {/*right*/}
  <div className="right">
    {/*sidebar_third*/}
    {/* EOF: sidebar_third*/}
  </div>
  <div className="clear" />
  {/* EOF: #main 
<div class="blank30"></div> */}

</>

  );
}

export default Header;
